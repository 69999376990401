import React from 'react'

import Group_Select from "./components/Group_Select"
const New_picture = () => {
  return (
    <div>
        
          <Group_Select />
        
    </div>
  )
}

export default New_picture